import React from "react"
import HeaderSub from "../../../components/header_sub"
import LayoutOuter from "../../../components/layout_outer"
import LayoutInner from "../../../components/layout_inner"
import BlogEntry from "../../../components/blog_entry"

export default function LaunchOfBeta() {
  return (
    <LayoutOuter>
      <LayoutInner>
        <div className="py-16">
          <HeaderSub
            title="André Thiele"
            subtitle="Launching the Tempory Beta"
          />
          <div className="pt-16">
            <BlogEntry
              title="Launching the Tempory Beta"
              preview="We're now beginning the internal beta to stabilize the app before making it publicly available to ensure there are no problems on a variety of devices. Not all features are expected to work just yet but will be made available once the app is stable enough. We're also watching closely for any unexpected crashes and performance issues before releasing the first public version. We will be happy about any customer feedback and hopefully adjust the app to our customers' needs."
              author="André Thiele"
            />
          </div>
        </div>
      </LayoutInner>
    </LayoutOuter>
  )
}
